import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode } from "@impacgroup/angular-baselib";
import { FirmwaresService } from "./firmwares.service";
import { TranslateService } from "@ngx-translate/core";
import { Firmware } from "../../api-models/Firmware";
import { AvailableProductTypes, ProductType } from "@impacgroup/doerr-wildkamera-api-shared";

@Component({
    selector: "app-firmwaredetail",
    templateUrl: "./firmwaredetail.component.html",
    styleUrls: ["firmwaredetail.component.scss"]
})
export class FirmwareDetailComponent extends BaseComponent implements OnInit {
    private firmwareId?: string;
    public firmware?: Firmware;
    public imeiWhitelist: string = "";
    public invalidImei: boolean = false;

    private fileToUpload?: File;
    public productTypes: ProductType[] = AvailableProductTypes;

    constructor(private route: ActivatedRoute, private firmwaresService: FirmwaresService, private router: Router, private alertService: AlertService, private translateService: TranslateService, private _location: Location) {
        super();

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.firmwareId = params["id"];
                if (this.firmwareId) {
                    this.initEditMode();
                } else {
                    this.initAddMode();
                }
            })
        );
    }

    ngOnInit(): void {}

    initAddMode() {
        this.MODE = BaseComponentMode.ADD;
        this.firmware = new Firmware();
    }

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        if (!this.firmwareId) {
            return;
        }

        this.subscriptions.push(
            this.firmwaresService.firmwareDetail(this.firmwareId).subscribe({
                next: (result) => {
                    this.firmware = result;
                    this.imeiWhitelist = result.imeiWhitelist.join("\n");
                },
                error: (err) => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                }
            })
        );
    }

    save() {
        if (!this.firmware) {
            return;
        }

        this.validateAndParseImeiWhitelist();
        if (this.invalidImei) {
            return;
        }

        if (this.MODE === BaseComponentMode.ADD) {
            if (!this.fileToUpload) {
                this.alertService.addCustomErrorByI18nId("firmwares.detail.validation.file");
                return;
            }

            this.subscriptions.push(
                this.firmwaresService.addFirmware(this.firmware, this.fileToUpload).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("firmwares.detail.add.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }

        if (this.MODE === BaseComponentMode.EDIT) {
            this.subscriptions.push(
                this.firmwaresService.updateFirmware(this.firmware, this.fileToUpload).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("firmwares.detail.edit.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }
    }

    back() {
        this._location.back();
    }

    async handleFileInput(ev: Event): Promise<void> {
        const eventTarget = ev.target as HTMLInputElement | null;
        const files = eventTarget?.files;

        if ((files?.length ?? 0) === 0) {
            return;
        }

        this.fileToUpload = files?.item(0) || undefined;
        if (this.fileToUpload && this.firmware) {
            this.firmware.originalname = this.fileToUpload.name;
        }
    }

    validateAndParseImeiWhitelist(): boolean {
        if (!this.firmware) {
            return false;
        }

        if (this.imeiWhitelist === "") {
            this.invalidImei = false;
            this.firmware.imeiWhitelist = [];
            return this.invalidImei;
        }

        const imeiArray = this.imeiWhitelist.split(/\r?\n/);
        for (const imei of imeiArray) {
            if (imei.length !== 15) {
                this.invalidImei = true;
                return this.invalidImei;
            }
        }

        this.invalidImei = false;
        this.firmware.imeiWhitelist = imeiArray;
        return this.invalidImei;
    }
}
