import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { Observable, OperatorFunction, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { CameraCorruptPictureOutputDTO, Cameras4GProRepository } from "./cameras4gpro.repository";
import { CameraCreateOrReplace4GPro, CameraDetail4GPro, CameraDetail4GProHelper, CameraDetail4GProSettings, CorruptPicture } from "./cameradetail4gpro.viewmodel";
import * as moment from "moment";
import {
    V2AdminCameraApiServiceCreateOrReplace4GProCameraRequestDTO,
    V2AdminCameraApiServiceRead4GProCameraResponseDTO,
    V2AdminCameraApiServiceRead4GProCameraResponseSettingsDTO,
    V2AdminCameraApiServiceTriggerCommand4GProCameraRequestDTO,
    V2AdminCameraApiServiceUpdate4GProCameraRequestDTO
} from "@impacgroup/doerr-wildkamera-api-dtos";
import { NumberHelper } from "src/app/global/helpers/NumberHelper";
import { DateHelper } from "src/app/global/helpers/DateHelper";
import { ProCameraCommandKey, ProductTypeKey } from "@impacgroup/doerr-wildkamera-api-shared";
import { plainToInstance } from "class-transformer";
import { formatDate } from "@angular/common";
import { StringHelper } from "src/app/global/helpers/StringHelper";

@Injectable()
export class Cameras4GProService {
    constructor(private cameras4GProRepository: Cameras4GProRepository, @Inject(LOCALE_ID) private locale: string) {}

    public createOrReplaceCamera(vm: CameraCreateOrReplace4GPro, productType: ProductTypeKey): Observable<void> {
        return this.cameras4GProRepository.createOrReplace(this.convertCreateOrReplaceVMtoDTO(vm), productType).pipe(mergeMap(() => of(void 0)));
    }

    public read({ id, start, end, productType }: { id: string; start?: Date; end?: Date; productType: ProductTypeKey }): Observable<CameraDetail4GPro> {
        return this.cameras4GProRepository.read({ id, start, end, productType }).pipe(this.convertDTOtoVM());
    }

    public readCorruptPictureStatistics({ id, type, start, end, productType }: { id: string; type: string; start: Date; end: Date; productType: ProductTypeKey }): Observable<CorruptPicture[]> {
        return this.cameras4GProRepository.readCorruptStatistics({ id, start, end, productType }).pipe(map(this.convertCorruptPictureDTOtoVM()));
    }

    public update(id: string, vm: CameraDetail4GPro, productType: ProductTypeKey): Observable<CameraDetail4GPro> {
        return this.cameras4GProRepository.update(id, this.convertVMtoDTO(vm), productType).pipe(this.convertDTOtoVM());
    }

    public sendCommand(id: string, command: ProCameraCommandKey, productType: ProductTypeKey): Observable<CameraDetail4GPro> {
        return this.cameras4GProRepository.sendCommand(id, this.convertCommandtoDTO(command), productType).pipe(this.convertDTOtoVM());
    }

    public timezonelist(productType: ProductTypeKey): Observable<string[]> {
        return this.cameras4GProRepository.timezonelist(productType);
    }

    private convertDTOtoVM(): OperatorFunction<V2AdminCameraApiServiceRead4GProCameraResponseDTO, CameraDetail4GPro> {
        return map((camera) => {
            return {
                ...camera,
                id: camera._id,

                batteryLevelReadable: camera.isBatteryLevelCharging ? "charging" : camera.batteryLevel ? camera.batteryLevel + "%" : "",
                ownerReadable: camera.owner ? (camera.owner.firstname && camera.owner.lastname ? `${camera.owner.firstname} ${camera.owner.lastname} (${camera.owner.email})` : camera.owner.email) : "",
                corrupt: {
                    pictures: camera.corrupt?.pictures?.map((picture) => {
                        return { ...picture, meta: formatDate(picture.date, "yyyy-MM-dd", this.locale) };
                    }),
                    picturesReadable: camera.corrupt?.pictures?.map((corruptPic) => corruptPic.value).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                },
                availableCommands: { ...camera.availableCommands },
                proSettings: this.convertSettingsDTOtoVM(camera.proSettings)
            };
        });
    }

    private convertSettingsDTOtoVM(settings?: V2AdminCameraApiServiceRead4GProCameraResponseSettingsDTO): CameraDetail4GProSettings {
        if (!settings) {
            return CameraDetail4GProHelper.defaultSettings();
        }

        const delayArray = settings?.delay?.split(":");
        const delayActive = !!settings?.delay && settings.delay !== "OFF" && (delayArray?.length ?? 0) > 1;

        const timeLapseArray = settings?.timeLapse?.split(":");
        const timeLapseActive = !!settings?.timeLapse && settings.timeLapse !== "OFF" && (timeLapseArray?.length ?? 0) > 1;

        const workTimer1Array = settings?.workTimer1?.split("-");
        const workTimer1Active = !!settings?.workTimer1 && settings.workTimer1 !== "OFF" && (workTimer1Array?.length ?? 0) > 0;

        const workTimer2Array = settings?.workTimer2?.split("-");
        const workTimer2Active = !!settings?.workTimer2 && settings.workTimer2 !== "OFF" && (workTimer2Array?.length ?? 0) > 0;

        const maxNumberPerDay = settings.maxNumPerDay ? parseInt(settings.maxNumPerDay, 10) : undefined;
        const maxNumberPerDayActive = !!settings?.maxNumPerDay && settings.maxNumPerDay !== "OFF";

        return {
            ...settings,

            videoLength: settings.videoLength ? parseInt(settings.videoLength, 10) : undefined,

            timezoneIdentifier: (settings.timezoneIdentifier ?? "") !== "" ? settings.timezoneIdentifier : Intl.DateTimeFormat().resolvedOptions().timeZone,

            delayActive: delayActive,
            delayHH: delayActive ? delayArray?.[0] : undefined,
            delayMM: delayActive ? delayArray?.[1] : undefined,
            delaySS: delayActive ? delayArray?.[2] : undefined,

            timeLapseActive: timeLapseActive,
            timeLapseHH: timeLapseActive ? timeLapseArray?.[0] : undefined,
            timeLapseMM: timeLapseActive ? timeLapseArray?.[1] : undefined,
            timeLapseSS: timeLapseActive ? timeLapseArray?.[2] : undefined,

            workTimer1Active: workTimer1Active,
            workTimer1From: workTimer1Active ? moment(workTimer1Array?.[0], "HH:mm").toDate() : undefined,
            workTimer1To: workTimer1Active ? moment(workTimer1Array?.[1], "HH:mm").toDate() : undefined,

            workTimer2Active: workTimer2Active,
            workTimer2From: workTimer2Active ? moment(workTimer2Array?.[0], "HH:mm").toDate() : undefined,
            workTimer2To: workTimer2Active ? moment(workTimer2Array?.[1], "HH:mm").toDate() : undefined,

            sdLoop: settings.sdLoop === "ON",
            autoscaleMode: settings.autoscaleMode === "ON",
            maxNumPerDayActive: maxNumberPerDayActive,
            maxNumPerDay: maxNumberPerDayActive ? maxNumberPerDay : 0,
            gpsMode: settings.gpsMode === "ON"
        };
    }

    private convertCorruptPictureDTOtoVM(): (value: CameraCorruptPictureOutputDTO[], index: number) => CorruptPicture[] {
        return (pictures) =>
            pictures
                .filter((picture): picture is Required<CameraCorruptPictureOutputDTO> => typeof picture.value === "number" && typeof picture.date === "object" && typeof picture.date.getMonth === "function")
                .map((picture) => {
                    return { ...picture, meta: formatDate(picture.date!, "yyyy-MM-dd", this.locale) };
                });
    }

    private convertSettingsVMToDTOReadyObj(cameraSettings?: CameraDetail4GProSettings) {
        const defaultSettings = CameraDetail4GProHelper.defaultSettings();

        return {
            ...cameraSettings,
            videoLength: NumberHelper.convertToPaddedString(cameraSettings?.videoLength ?? defaultSettings.videoLength),

            delay: cameraSettings?.delayActive ? [StringHelper.zeroPaddedString(cameraSettings.delayHH), StringHelper.zeroPaddedString(cameraSettings.delayMM), StringHelper.zeroPaddedString(cameraSettings.delaySS)].join(":") : "OFF",
            timeLapse: cameraSettings?.timeLapseActive
                ? [StringHelper.zeroPaddedString(cameraSettings.timeLapseHH), StringHelper.zeroPaddedString(cameraSettings.timeLapseMM), StringHelper.zeroPaddedString(cameraSettings.timeLapseSS)].join(":")
                : "OFF",

            workTimer1: cameraSettings?.workTimer1Active ? [DateHelper.convertToWorkTimer(cameraSettings.workTimer1From), DateHelper.convertToWorkTimer(cameraSettings.workTimer1To)].join("-") : "OFF",
            workTimer2: cameraSettings?.workTimer2Active ? [DateHelper.convertToWorkTimer(cameraSettings.workTimer2From), DateHelper.convertToWorkTimer(cameraSettings.workTimer2To)].join("-") : "OFF",
            autoscaleMode: cameraSettings?.autoscaleMode ? "ON" : "OFF",
            sdLoop: cameraSettings?.sdLoop ? "ON" : "OFF",
            maxNumPerDay: cameraSettings?.maxNumPerDayActive && cameraSettings?.maxNumPerDay && cameraSettings.maxNumPerDay > 0 ? NumberHelper.convertToPaddedString(cameraSettings.maxNumPerDay) : "OFF",
            gpsMode: cameraSettings?.gpsMode ? "ON" : "OFF"
        };
    }

    private convertCommandtoDTO(command: ProCameraCommandKey): V2AdminCameraApiServiceTriggerCommand4GProCameraRequestDTO {
        return {
            command: command
        };
    }

    private convertVMtoDTO(vm: CameraDetail4GPro | CameraCreateOrReplace4GPro): V2AdminCameraApiServiceUpdate4GProCameraRequestDTO {
        return plainToInstance(
            V2AdminCameraApiServiceUpdate4GProCameraRequestDTO,
            {
                ...vm,
                proSettings: this.convertSettingsVMToDTOReadyObj(vm.proSettings)
            },
            { excludeExtraneousValues: true }
        );
    }

    private convertCreateOrReplaceVMtoDTO(vm: CameraDetail4GPro | CameraCreateOrReplace4GPro): V2AdminCameraApiServiceCreateOrReplace4GProCameraRequestDTO {
        return plainToInstance(
            V2AdminCameraApiServiceCreateOrReplace4GProCameraRequestDTO,
            {
                ...vm,
                proSettings: this.convertSettingsVMToDTOReadyObj(vm.proSettings)
            },
            { excludeExtraneousValues: true }
        );
    }
}
