import { Exclude, Type } from "class-transformer";
import * as moment from "moment";

export enum UserAboProvider {
    klarna = "klarna",
    paypal = "paypal",
    apple = "apple",
    google = "google",
    free = "free" // if use has free hd or free quota (without payment, role driven by dörr)
}

export interface IUserAboElem {
    nextBillingDate: Date | null;
    lastBillingDate: Date | null;
    canceled: Date | null;
    cancelReason: string | null;
    active: boolean;
    provider: UserAboProvider;
    productId?: string; // if provider is apple or google => productId of subscription
    id: string;
    created: Date;
    lastRealtimeStatus?: number;
    mbStorage?: number;
}

export interface IUserAboInformation {
    highres: IUserAboElem | null;
    quota: IUserAboElem[];
    availableQuota: number;
    usedQuota: number;
    allowedFavoriteSharePercentage: number;
    camMaySendHighRes: boolean;
    waitingPeriodDays: number;
    camLowResWaitingPeriodDays: number;
    freeHd: boolean;
    freeQuota: boolean;
}

export class AboUser {
    _id?: string;

    email?: string;
    firstname?: string;
    lastname?: string;
    userAboInfo?: IUserAboInformation;

    version?: number;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    created?: Date;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    updated?: Date;

    public getHighResDeletionDate(): Date | null {
        if (this.userAboInfo?.highres && this.userAboInfo?.highres?.nextBillingDate) {
            return moment(this.userAboInfo.highres.nextBillingDate).add(this.userAboInfo.waitingPeriodDays, "days").toDate();
        }
        return null;
    }

    public getHighResCamResetDate(): Date | null {
        if (this.userAboInfo?.highres && this.userAboInfo?.highres?.nextBillingDate) {
            return moment(this.userAboInfo.highres.nextBillingDate).add(this.userAboInfo.camLowResWaitingPeriodDays, "days").toDate();
        }
        return null;
    }
}
