<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'EDIT'">{{ "cameras.detail.edit.title" | translate }}</h1>

        <div class="actions">
            <button *ngIf="grafanaMetricsUrl" (click)="openMetrics()" class="btn btn-success btn--icon-text ml-4"><i class="zmdi zmdi-link"></i> {{ "cameras.detail.grafanametrics.title" | translate }}</button>
            <button (click)="back()" class="btn btn-secondary btn--icon-text ml-4"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body">
            <form name="form" class="row" *ngIf="camera" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.imei" | translate }}</label>
                        <input name="imei" type="text" class="form-control" [(ngModel)]="camera.imei" readonly="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.battery" | translate }}</label>
                        <input name="battery" type="text" class="form-control" [ngClass]="{ 'text-danger': camera.batteryLow }" [(ngModel)]="camera.batteryLevelReadable" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.firmware" | translate }}</label>
                        <input name="firmware" type="text" class="form-control" [(ngModel)]="camera.firmware" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.cameraType" | translate }}</label>
                        <input name="cameraType" type="text" class="form-control" [(ngModel)]="camera.productType" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.user" | translate }}</label>
                        <input name="user" type="text" class="form-control" [ngModel]="camera.ownerReadable" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.batteryType" | translate }}</label>
                        <input name="batteryType" type="text" class="form-control" value="{{ 'cameras4gpro.props.battery.type.' + camera.proSettings?.batteryType | translate }}" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.firmwareUpdateBatteryLow" | translate }}</label>
                        <input name="firmwareUpdateBatteryLow" type="text" class="form-control" [ngModel]="camera.firmwareUpdateBatteryLow" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.realtimeConnectionEstablished" | translate }}</label>
                        <input name="realtimeConnectionEstablished" type="text" class="form-control" [ngModel]="camera.realtimeConnectionEstablished" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.settingsMissmatch" | translate }}</label>
                        <input name="settingsMissmatch" type="text" class="form-control" [ngModel]="camera.settingsMissmatch" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.settingsSynchronized" | translate }}</label>
                        <input name="settingsSynchronized" type="text" class="form-control" [ngModel]="camera.settingsSynchronized" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.csqStatus" | translate }}</label>
                        <input name="csqStatus" type="text" class="form-control" [ngModel]="camera.csqStatus" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.signalStrength" | translate }}</label>
                        <input name="signalStrength" type="text" class="form-control" [ngModel]="camera.signalStrength" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.powered" | translate }}</label>
                        <input name="powered" type="text" class="form-control" [ngModel]="camera.powered" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.latestSyncUpdate" | translate }}</label>
                        <input name="latestSyncUpdate" type="text" class="form-control" [ngModel]="camera.latestSyncUpdate | date : UTCDATEFORMAT" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.latestMediaUpload" | translate }}</label>
                        <input name="latestMediaUpload" type="text" class="form-control" [ngModel]="camera.latestMediaUpload | date : UTCDATEFORMAT" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.corruptPictures" | translate }}</label>
                        <input name="corruptPictures" type="text" class="form-control" [ngModel]="camera.corrupt?.picturesReadable" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6" *ngIf="camera.apn">
                    <div class="form-group">
                        <label>{{ "cameras.props.apn" | translate }}</label>
                        <input name="apn" type="text" class="form-control" [ngModel]="camera.apn" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6" *ngIf="camera.apnMccMmc">
                    <div class="form-group">
                        <label>{{ "cameras.props.apnMccMmc" | translate }}</label>
                        <input name="apnMccMmc" type="text" class="form-control" [ngModel]="camera.apnMccMmc" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6" *ngIf="camera.apnUser">
                    <div class="form-group">
                        <label>{{ "cameras.props.apnUser" | translate }}</label>
                        <input name="apnUser" type="text" class="form-control" [ngModel]="camera.apnUser" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6" *ngIf="camera.apnPassword">
                    <div class="form-group">
                        <label>{{ "cameras.props.apnPassword" | translate }}</label>
                        <input name="apnPassword" type="text" class="form-control" [ngModel]="camera.apnPassword" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6" *ngIf="camera.reportFailedPictureUpload === true || camera.reportFailedPictureUpload === false">
                    <div class="form-group">
                        <label>{{ "cameras.props.reportFailedPictureUpload" | translate }}</label>
                        <input name="reportFailedPictureUpload" type="text" class="form-control" [ngModel]="camera.reportFailedPictureUpload" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6" *ngIf="camera.reportFailedVideoUpload === true || camera.reportFailedVideoUpload === false">
                    <div class="form-group">
                        <label>{{ "cameras.props.reportFailedVideoUpload" | translate }}</label>
                        <input name="reportFailedVideoUpload" type="text" class="form-control" [ngModel]="camera.reportFailedVideoUpload" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.sdCapacity" | translate }}</label>
                        <input name="sdCapacity" type="text" class="form-control" [class.text-danger]="camera.sdcardFull" [class.is-invalid]="camera.sdcardFull" [ngModel]="camera.sdCapacity" disabled="true" />
                        <i class="form-group__bar"></i>
                        <span class="invalid-tooltip" *ngIf="camera.sdcardFull">{{ "cameras.props.sdCardFull" | translate }}</span>
                    </div>
                </div>

                <div class="col-md-6" *ngIf="camera.isUninitialized">
                    <div class="form-group">
                        <label>{{ "cameras.props.InitializationStatus" | translate }}</label>
                        <div class="input-group ml-0 ms-0">
                            <span class="form-control text-danger pl-0"
                                ><b>{{ "cameras.props.uninitialized" | translate }}</b></span
                            >
                            <div class="input-group-append">
                                <span class="input-group-text bg-danger text-white"><i class="zmdi zmdi-lock"></i></span>
                            </div>
                        </div>

                        <i class="form-group__bar"></i>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!-- 4g pro command -->
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">{{ "cameras4gpro.detail.subscription.title" | translate }}</h4>

            <form name="form2" class="row" *ngIf="camera" (ngSubmit)="f2.form.valid && save()" #f2="ngForm" novalidate>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.usedQuotaMB" | translate }}</label>
                        <input name="usedQuotaMB" type="text" class="form-control" [ngModel]="camera.usedQuotaMB" readonly="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.availableQuotaMB" | translate }}</label>
                        <input name="availableQuotaMB" type="text" class="form-control" [ngModel]="camera.availableQuotaMB" readonly="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!-- 4g pro command -->
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">{{ "cameras4gpro.detail.command.title" | translate }}</h4>

            <div *ngIf="camera?.availableCommands" class="d-flex flex-column justify-content-left command-gap">
                <div class="d-flex flex-wrap command-gap justify-content-between">
                    <div class="d-flex flex-wrap command-gap">
                        <button *ngIf="camera?.availableCommands?.takePicture" class="btn btn-success" (click)="takePicture()">
                            <span>{{ "cameras4gpro.detail.command.take_picture.button" | translate }}</span>
                        </button>
                        <button *ngIf="camera?.availableCommands?.takeVideo" class="btn btn-success" (click)="takeVideo()">
                            <span>{{ "cameras4gpro.detail.command.take_video.button" | translate }}</span>
                        </button>
                        <button *ngIf="camera?.availableCommands?.init" class="btn btn-success" (click)="initCamera()">
                            <span>{{ "cameras4gpro.detail.command.init.button" | translate }}</span>
                        </button>
                    </div>
                    <div class="d-flex flex-wrap command-gap">
                        <button *ngIf="camera?.availableCommands?.cameraRestart" class="btn btn-warning" (click)="restartCamera()">
                            <span>{{ "cameras4gpro.detail.command.camera_restart.button" | translate }}</span>
                        </button>
                        <button *ngIf="camera?.availableCommands?.firmwareUpdate" class="btn btn-warning" (click)="updateFirmware()">
                            <span>{{ "cameras4gpro.detail.command.firmware_update.button" | translate }}</span>
                        </button>
                    </div>
                </div>
                <div class="d-flex flex-wrap command-gap justify-content-between">
                    <div class="d-flex flex-wrap command-gap">
                        <button *ngIf="camera?.availableCommands?.sendStatus" class="btn btn-success" (click)="sendStatus()">
                            <span>{{ "cameras4gpro.detail.command.send_status.button" | translate }}</span>
                        </button>
                        <button *ngIf="camera?.availableCommands?.syncSettings" class="btn btn-success" (click)="syncSettings()">
                            <span>{{ "cameras4gpro.detail.command.sync_settings.button" | translate }}</span>
                        </button>
                    </div>
                    <div class="d-flex flex-wrap command-gap">
                        <button *ngIf="camera?.availableCommands?.formatSDCard" class="btn btn-danger" (click)="formatSDCard()">
                            <span>{{ "cameras4gpro.detail.command.format_sdcard.button" | translate }}</span>
                        </button>
                        <button *ngIf="camera?.availableCommands?.cameraTurnOn" class="btn btn-danger" (click)="cameraTurnOn()">
                            <span>{{ "cameras4gpro.detail.command.camera_turn_on.button" | translate }}</span>
                        </button>
                        <button *ngIf="camera?.availableCommands?.cameraTurnOff" class="btn btn-danger" (click)="cameraTurnOff()">
                            <span>{{ "cameras4gpro.detail.command.camera_shutdown.button" | translate }}</span>
                        </button>
                        <button *ngIf="camera?.availableCommands?.cameraReset" class="btn btn-danger" (click)="cameraReset()">
                            <span>{{ "cameras4gpro.detail.command.camera_reset.button" | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <app-camera-data-and-settings-4g-pro *ngIf="camera" [camera]="camera" (saveClickedAndValid)="save()" (reconnectSendSuccessful)="initEditMode()"></app-camera-data-and-settings-4g-pro>
        </div>
    </div>

    <!-- corrupt pictures / statistic -->
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">{{ "cameras.detail.corruptstats.title" | translate }}</h4>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            type="text"
                            id="start"
                            name="start"
                            [(ngModel)]="start"
                            placeholder="DD.MM.YYYY"
                            class="form-control"
                            bsDatepicker
                            [bsConfig]="{ adaptivePosition: true, isAnimated: true, containerClass: 'date-picker', dateInputFormat: 'DD.MM.YYYY' }"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            type="text"
                            id="end"
                            name="end"
                            [(ngModel)]="end"
                            placeholder="DD.MM.YYYY"
                            class="form-control"
                            bsDatepicker
                            [bsConfig]="{ adaptivePosition: true, isAnimated: true, containerClass: 'date-picker', dateInputFormat: 'DD.MM.YYYY' }"
                        />
                    </div>
                </div>

                <div class="col-12">
                    <button class="float-right btn btn-success btn--icon-text" (click)="loadStatisticData()"><i class="zmdi zmdi-calendar"></i> {{ "global.buttons.filter" | translate }}</button>
                </div>
            </div>
            <div class="row">
                <x-chartist [configuration]="chartistConfig"> </x-chartist>
            </div>
        </div>
    </div>
</div>
